import 'react-datepicker/dist/react-datepicker.css';

import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';

import './datatables.scss';
import { truncStringPortion } from '../../helpers/formatter.js';
import CSV from '../../components/Common/CSV';
import { dictAliases, fetchERC20config } from '../../helpers/api';

const abiDecoder = require('abi-decoder');

class EthProposals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decimals: 100,
      startDate: new Date(),
      activeTab: '1',
      csvRows: [],
      cfgEth: {},
      aliases: [],
      transactions: {
        columns: [
          {
            label: 'Proposal Hash',
            field: 'proposalId',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Nonce',
            field: 'nonce',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Date',
            field: 'date',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Operation',
            field: 'operation',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Parameters',
            field: 'parameters',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Approvals',
            field: 'approvals',
            sort: 'asc',
            width: 104,
          },
          {
            label: 'Rejects',
            field: 'rejects',
            sort: 'asc',
            width: 104,
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'asc',
            width: 104,
          },
          {
            label: 'Actions',
            field: 'action',
            sort: 'asc',
            width: 100,
          },
        ],
        rows: [],
      },
      limit: false,
      loading: true,
      threshold: '-',
    };
    this.handleChange.bind(this);
  }

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  componentDidMount = async () => {
    let aliases = await dictAliases();
    this.setState({ aliases: aliases.data });
    let cfgEth = await fetchERC20config();
    await this.setState({ cfgEth: cfgEth.data });
    const role = this.props.match.params.role;
    const safeAddr =
      role === 'administrator'
        ? this.state.cfgEth.administrator
        : role === 'master_minter'
        ? this.state.cfgEth.master_minter
        : role === 'owner'
        ? this.state.cfgEth.owner
        : this.state.cfgEth.reserve;
    const transactionServiceUrl = this.state.cfgEth.safe_tx_url;
    let resp = await axios.get(
      `${transactionServiceUrl}/api/v1/safes/${safeAddr}`,
    );
    const threshold = resp.data.threshold;
    this.setState({ threshold });
    resp = await axios.get(
      `${transactionServiceUrl}/api/v1/safes/${safeAddr}/multisig-transactions`,
    );
    resp = resp.data;
    console.log('DATA', resp);
    abiDecoder.addABI(cfgEth.data.abi);
    for (let tx of resp.results.sort(
      (a, b) => parseInt(b.nonce) - parseInt(a.nonce),
    )) {
      try {
        let decodedData = abiDecoder.decodeMethod(tx.data);
        tx.dataDecoded = {
          method: decodedData.name,
          parameters: decodedData.params,
        };
      } catch (e) {
        console.log(e);
      }
      if (
        !this.state.transactions ||
        !this.state.transactions.rows.some((row) => row.nonce === tx.nonce)
      ) {
        const rejects =
          !tx.dataDecoded &&
          tx.confirmations &&
          tx.confirmations.length >= threshold
            ? tx.confirmations
            : [];
        const accepts = rejects.length === 0 ? tx.confirmations : [];
        let row = {
          proposalId: truncStringPortion(tx.safeTxHash, 8, 8),
          nonce: tx.nonce,
          date: new Date(tx.submissionDate).toLocaleString(),
          operation: tx.dataDecoded ? (
            <b>{tx.dataDecoded.method}</b>
          ) : (
            <b>ETH Transfer</b>
          ),
          parameters: (
            <>
              {tx.dataDecoded &&
                tx.dataDecoded.parameters.map((e) => (
                  <small key={e.name} className="d-block">
                    {e.type === 'uint256' && e.name !== '_threshold'
                      ? 'amount'
                      : e.name}{' '}
                    :{' '}
                    {e.type === 'address'
                      ? this.state.aliases[e.value.toLowerCase()] &&
                        this.state.aliases[e.value.toLowerCase()].name
                        ? this.state.aliases[e.value.toLowerCase()].name
                        : truncStringPortion(e.value, 8, 8)
                      : e.type === 'uint256' && e.name !== '_threshold'
                      ? `${(
                          e.value / this.state.cfgEth.decimals
                        ).toLocaleString()} EUROP`
                      : e.value}
                  </small>
                ))}
              {parseFloat(tx.value) > 0 && (
                <small className="d-block">
                  {parseFloat(tx.value) / 10 ** 18}ETH
                </small>
              )}
            </>
          ),
          approvals: (
            <small>
              {accepts.map(
                (confirmation, index) =>
                  confirmation.owner && (
                    <span key={`appr-${index}`} className="d-block">
                      {this.state.aliases[confirmation.owner.toLowerCase()] &&
                      this.state.aliases[confirmation.owner.toLowerCase()].name
                        ? this.state.aliases[confirmation.owner.toLowerCase()]
                            .name
                        : truncStringPortion(confirmation.owner, 8, 8)}
                    </span>
                  ),
              )}
            </small>
          ),
          rejects: (
            <small>
              {rejects.map(
                (confirmation, index) =>
                  confirmation.owner && (
                    <span key={`rejc-${index}`} className="d-block">
                      {this.state.aliases[confirmation.owner.toLowerCase()] &&
                      this.state.aliases[confirmation.owner.toLowerCase()].name
                        ? this.state.aliases[confirmation.owner.toLowerCase()]
                            .name
                        : truncStringPortion(confirmation.owner, 8, 8)}
                    </span>
                  ),
              )}
            </small>
          ),
          status: (
            <b>
              {tx.isExecuted
                ? 'Closed'
                : tx.confirmations.length >= threshold
                ? 'Closed'
                : 'Pending'}
              <br />
              {tx.confirmations.length >= threshold ? (
                tx.isExecuted ? (
                  <span className="badge badge-success font-size-10">
                    Completed
                  </span>
                ) : (
                  <span className="badge badge-info font-size-10">
                    Awaiting execution
                  </span>
                )
              ) : (
                <span className="badge badge-warning font-size-10">Open</span>
              )}
            </b>
          ),
          action:
            tx.isExecuted && tx.dataDecoded ? (
              <Link
                as="span"
                className="badge badge-primary font-size-11"
                style={{ cursor: 'pointer' }}
                to={`/eth-proposal/${role}/${tx.safeTxHash}`}
              >
                View Details
              </Link>
            ) : tx.confirmations.length >= threshold && tx.dataDecoded ? (
              <Link
                as="span"
                className="badge badge-primary font-size-11"
                style={{ cursor: 'pointer' }}
                to={`/eth-proposal/${role}/${tx.safeTxHash}`}
              >
                View Details
              </Link>
            ) : (
              tx.dataDecoded && (
                <Link
                  as="span"
                  className="badge badge-warning font-size-11"
                  style={{ cursor: 'pointer' }}
                  to={`/eth-proposal/${role}/${tx.safeTxHash}`}
                >
                  Accept / Reject
                </Link>
              )
            ),
        };

        let csvRow = {
          proposalId: tx.safeTxHash,
          nonce: tx.nonce,
          date: tx.submissionDate,
          operation: tx.dataDecoded && tx.dataDecoded.method,
          parameters:
            tx.dataDecoded && JSON.stringify(tx.dataDecoded.parameters),
          approvals: accepts,
          rejects: rejects,
          status: tx.isSuccessful
            ? accepts > rejects
              ? 'Accepted'
              : 'Rejected'
            : 'Open',
          action: `https://${window.location.hostname}/eth-proposal/${role}/${tx.safeTxHash}`,
        };

        this.setState({
          transactions: {
            columns: [...this.state.transactions.columns],
            rows: [...this.state.transactions.rows, row],
          },
          csvRows: [...this.state.csvRows, csvRow],
        });
      } else if (this.state.transactions) {
        let row = this.state.transactions.rows.filter(
          (row) => row.nonce === tx.nonce,
        )[0];
        let csvRow = this.state.csvRows.filter(
          (row) => row.nonce === tx.nonce,
        )[0];

        row.operation = tx.dataDecoded && (
          <b>
            <small className="text-danger font-weight-bold">reject:</small>{' '}
            {tx.dataDecoded.method}
          </b>
        );
        row.parameters =
          tx.dataDecoded &&
          tx.dataDecoded.parameters.map((e) => (
            <small key={e.name} className="d-block">
              {e.type === 'uint256' ? 'amount' : e.type}:{' '}
              {e.type === 'address'
                ? this.state.aliases[e.value.toLowerCase()] &&
                  this.state.aliases[e.value.toLowerCase()].name
                  ? this.state.aliases[e.value.toLowerCase()].name
                  : truncStringPortion(e.value, 8, 8)
                : e.value}
            </small>
          ));
        row.status = (
          <b>
            {tx.isExecuted
              ? 'Closed'
              : csvRow.approvals.length >= threshold ||
                csvRow.rejects.length >= threshold
              ? 'Closed'
              : 'Pending'}
            <br />
            {csvRow.approvals.length >= threshold ||
            csvRow.rejects.length >= threshold ? (
              <span className="badge badge-success font-size-10">
                Completed
              </span>
            ) : tx.isExecuted ? (
              <span className="badge badge-success font-size-10">
                Completed
              </span>
            ) : (
              <span className="badge badge-warning font-size-10">Open</span>
            )}
          </b>
        );
        row.action = tx.isExecuted ? (
          <Link
            as="span"
            className="badge badge-primary font-size-11"
            style={{ cursor: 'pointer' }}
            to={`/eth-proposal/${role}/${csvRow.proposalId}`}
          >
            View Details
          </Link>
        ) : csvRow.approvals.length >= threshold ||
          csvRow.rejects.length >= threshold ? (
          <Link
            as="span"
            className="badge badge-primary font-size-11"
            style={{ cursor: 'pointer' }}
            to={`/eth-proposal/${role}/${csvRow.proposalId}`}
          >
            View Details
          </Link>
        ) : (
          <Link
            as="span"
            className="badge badge-danger font-size-11"
            style={{ cursor: 'pointer' }}
            to={`/eth-proposal/${role}/${csvRow.proposalId}?action=reject`}
          >
            Close reject
          </Link>
        );

        csvRow.operation = tx.dataDecoded && `reject: ${tx.dataDecoded.method}`;
        csvRow.parameters =
          tx.dataDecoded && JSON.stringify(tx.dataDecoded.parameters);

        let rows = this.state.transactions.rows;
        rows.pop();
        let csvRows = this.state.csvRows;
        csvRows.pop();
        await this.setState({
          transactions: {
            columns: [...this.state.transactions.columns],
            rows: [...rows, row],
          },
          csvRows: [...csvRows, csvRow],
        });
      }
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {!this.state.loading && (
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <h4 className="card-title">
                        ERC20{' '}
                        {this.props.match.params.role === 'administrator'
                          ? 'Administrator'
                          : this.props.match.params.role === 'master_minter'
                          ? 'Master minter'
                          : this.props.match.params.role === 'owner'
                          ? 'Owner'
                          : 'Reserve'}{' '}
                        proposals |{' '}
                        <small>Threshold {this.state.threshold}</small>
                      </h4>
                      <TabContent activeTab={'1'} className="p-3">
                        <TabPane tabId="1" id="all-order">
                          <MDBDataTable
                            responsive
                            bordered
                            data={this.state.transactions}
                            className="mt-1"
                            hover
                          />
                          <CSV
                            name="master_minter_proposals"
                            transactions={this.state.transactions}
                            csvRows={this.state.csvRows}
                          />
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {this.state.loading && (
              <div className="text-center my-3">
                <Link to="#" className="text-primary">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                  Loading{' '}
                </Link>
              </div>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default EthProposals;
